import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { Link } from 'gatsby';

import { StaticImage } from 'gatsby-plugin-image';

import '../../generic-page.scss';
import { Row, Col } from 'react-bootstrap';
import { HSRLightCone } from '../../../modules/hsr/common/components/hsr-light-cone';

const HSRGuidesFHPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page hsr-dps-rankings'} game="hsr">
      <ul className="breadcrumb">
        <li>
          <Link to="/star-rail/">Honkai: Star Rail</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/star-rail/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Best Battle Pass Light Cone</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/starrail/categories/category_bpcone.png"
            alt="Best Battle Pass Light Cone"
          />
        </div>
        <div className="page-details">
          <h1>Best Battle Pass Light Cone</h1>
          <h2>
            A guide for picking the best Light Cone in the Battle Pass available
            in Honkai: Star Rail.
          </h2>
          <p>
            Last updated: <strong>30/01/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <div className="page-content">
        <SectionHeader title="Foreword" />
        <p>
          <strong>
            Since you can only get 1 Light Cone per Battle Pass, the choice you
            do is a pretty important one
          </strong>
          . Also, considering the fact that it will take 5 months to actually
          superimpose the Light Cone to maximum level, you should consider
          picking multiple base versions first - as this will allow you to
          actually make use of them earlier.
        </p>
        <SectionHeader title="Abundance" />
        <Row xs={1} xxl={1} className="relic-set-container">
          <Col>
            <HSRLightCone mode="card" slug="warmth-shortens-cold-nights" />
          </Col>
        </Row>
        <p>The Abundance Light Cones can be split into two categories:</p>
        <ul>
          <li>Healing focused - they buff the healing output,</li>
          <li>
            Support focused - they help the Healer's allies in various ways.
          </li>
        </ul>
        <p>
          While there are a few Light Cones that are hybrids, majority of them
          fits into one of the two categories.
        </p>
        <p>
          <strong>Warmth Shortens Cold Nights</strong> is a Light Cone belonging
          to first category. It increases the Max HP of the Healer, which
          translates to increased survivability but also increased Healing
          (since it's based on their max HP), but on top of that, every time the
          Healer uses their Basic ATK, all allies will be healed based on their
          Max HP.
        </p>
        <p>
          Sadly, while the healing on Basic ATK looks great on paper, as you
          want your Healers to actually use their Basic ATK a lot,{' '}
          <strong>there's two huge issue with it</strong>. First of all, it
          doesn't improve the healing output of the character skills and
          furthermore it lacks utility the other Cones can provide.
        </p>
        <p>
          This makes this cone an inferior option compared to other Cones that
          provide either more Healing or more utility.
        </p>
        <SectionHeader title="Hunt" />
        <Row xs={1} xxl={1} className="relic-set-container">
          <Col>
            <HSRLightCone mode="card" slug="return-to-darkness" />
          </Col>
        </Row>
        <p>
          The <strong>Return to Darkness</strong> Light Cone is an interesting
          one among the currently available Hunt Light Cones,{' '}
          <strong>because it exchanges the raw damage boost for utility</strong>
          . There aren't that many sources of dispel in the game (dispel =
          removing buffs from the enemy) and Return to Darkness gives you the
          ability to do that. But at a cost, obviously.
        </p>
        <p>
          While in the majority of battles, you will prefer to use Light Cones
          that improve your character damage output as you want to kill things
          as fast as possible and you're simply losing too much to make it worth
          using - even at S5.
        </p>
        <p>
          Check the comparison below to see the difference in the damage based
          on Dan Heng simulation (same rules as in our DPS Ranking):
        </p>
        <div className="box">
          <h5>Hunt Cones damage comparison</h5>
          <div className="simulation-container">
            <div className="simulation-row no-image">
              <div className="chart">
                <div className="info-2">
                  <p>In the Night (S1)</p>
                </div>
                <div className="dps-bar-outside">
                  <div
                    className={`dps-bar star-5`}
                    style={{ width: '100%' }}
                  ></div>
                  <div className="data">
                    <span className="dps">345,563 dmg</span>
                    <span className="percent">100%</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="simulation-row no-image">
              <div className="chart">
                <div className="info-2">
                  <p>Sleep Like the Dead (S1)</p>
                </div>
                <div className="dps-bar-outside">
                  <div
                    className={`dps-bar star-5`}
                    style={{ width: '85.55%' }}
                  ></div>
                  <div className="data">
                    <span className="dps">295,640 dmg</span>
                    <span className="percent">85.55%</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="simulation-row no-image">
              <div className="chart">
                <div className="info-2">
                  <p>Swordplay (S1)</p>
                </div>
                <div className="dps-bar-outside">
                  <div
                    className={`dps-bar star-4`}
                    style={{ width: '83.07%' }}
                  ></div>
                  <div className="data">
                    <span className="dps">287,061 dmg</span>
                    <span className="percent">83.07%</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="simulation-row no-image">
              <div className="chart">
                <div className="info-2">
                  <p>Cruising in the Stellar Sea (S5)</p>
                </div>
                <div className="dps-bar-outside">
                  <div
                    className={`dps-bar star-5`}
                    style={{ width: '82.19%' }}
                  ></div>
                  <div className="data">
                    <span className="dps">284,002 dmg</span>
                    <span className="percent">82.19%</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="simulation-row no-image">
              <div className="chart">
                <div className="info-2">
                  <p>River Flows in Spring (S5)</p>
                </div>
                <div className="dps-bar-outside">
                  <div
                    className={`dps-bar star-4`}
                    style={{ width: '80.21%' }}
                  ></div>
                  <div className="data">
                    <span className="dps">277,179 dmg</span>
                    <span className="percent">80.21%</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="simulation-row no-image">
              <div className="chart">
                <div className="info-2">
                  <p>Only Silence Remains (S1)</p>
                </div>
                <div className="dps-bar-outside">
                  <div
                    className={`dps-bar star-4`}
                    style={{ width: '77.91%' }}
                  ></div>
                  <div className="data">
                    <span className="dps">269,225 dmg</span>
                    <span className="percent">77.91%</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="simulation-row no-image">
              <div className="chart">
                <div className="info-2">
                  <p>Return to Darkness (S1)</p>
                </div>
                <div className="dps-bar-outside">
                  <div
                    className={`dps-bar wind`}
                    style={{ width: '76.36%' }}
                  ></div>
                  <div className="data">
                    <span className="dps">263,861 dmg</span>
                    <span className="percent">76.36%</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
        <SectionHeader title="Preservation" />
        <Row xs={1} xxl={1} className="relic-set-container">
          <Col>
            <HSRLightCone mode="card" slug="this-is-me" />
          </Col>
        </Row>
        <p>
          The <strong>This is Me!</strong> Light Cone stands out from the other
          Preservation ones. While they are mostly focusing on either Shielding
          or Control, This is Me instead improves the character's damage.
        </p>
        <p>
          The Light Cone basically adds a new instance of damage that is based
          on the character's DEF, but keep in mind that Ultimates that affect
          allies (like Gepard's Ultimate), won't benefit from it. Though it
          would be funny if it did - Gepard the team killer. But kidding aside,
          even if March 7th and Fire MC can benefit from the Light Cone, the
          other options available to them are simply much stronger - even if the
          Cone also gives some DEF%.
        </p>
        <p>
          Maybe in the future there will be a Preservation character released
          that focuses mostly on doing damage, but till then it's hard to make
          use of this Light Cone.
        </p>
        <SectionHeader title="Harmony" />
        <Row xs={1} xxl={1} className="relic-set-container">
          <Col>
            <HSRLightCone mode="card" slug="carve-the-moon-weave-the-clouds" />
          </Col>
        </Row>
        <p>
          <strong>Carve the Moon, Weave the Clouds</strong> Light Cone gives a
          random buff each time the wearer turns start - while 2 of the buff are
          offensive, one of utility based. Overall while the offensive buffs are
          decent and everyone can benefit from them, the utility one - which
          gives Energy Regen - requires a lot of investment to affect a
          character's rotations and the value available on the cone isn't big
          enough to do it by itself.
        </p>
        <p>
          Furthermore, Harmony characters have a lot of great Light Cones that
          simply offer more AND are more consistent at the same time. So it's
          really hard to justify using this Light Cone over other options.
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
        <SectionHeader title="Nihility" />
        <Row xs={1} xxl={1} className="relic-set-container">
          <Col>
            <HSRLightCone mode="card" slug="we-will-meet-again" />
          </Col>
        </Row>
        <p>
          The <strong>We Will Meet Again</strong> Light Cone was a true wildcard
          when the game released, but now that we managed to test it properly,
          it's sadly not that good. Other Nihility options simply offer more
          damage and more consistency, so it's hard to justify using this Light
          Cone over for example Fermata or Good Night, Sleep well.
        </p>
        <SectionHeader title="Destruction" />
        <Row xs={1} xxl={1} className="relic-set-container">
          <Col>
            <HSRLightCone mode="card" slug="nowhere-to-run" />
          </Col>
        </Row>
        <p>
          The <strong>Nowhere to Run</strong> Light Cone on top of giving a
          decent ATK boost also has some utility attached to it in the form of
          self-healing triggered on kill - which also scales of the character
          ATK.
        </p>
        <p>
          Sadly, Fall of the Aeon exist and it simply outperforms this Light
          Cone damage wise and the self-healing part isn't needed on DPS
          characters - healers are enough to keep the team healthy, so you can
          focus on improving the damage output of your DPS without any issues.
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
        <SectionHeader title="Erudition" />
        <Row xs={1} xxl={1} className="relic-set-container">
          <Col>
            <HSRLightCone mode="card" slug="today-is-another-peaceful-day" />
          </Col>
        </Row>
        <p>
          The <strong>Today Is Another Peaceful Day</strong> Light Cone is a
          pretty unique one as it increases the damage the character deals based
          on their max Energy - which is equal to their Ultimate cost. The
          higher the cost, the bigger the benefit.
        </p>
        <p>
          For many Erudtion characters it actually is their Best in Slot (or in
          top 3) and often it can even reach the numbers provided by some
          Erudition signature Light Cones, making it the best Cone available in
          the Battle Pass.
        </p>
        <SectionHeader title="Which Light Cone to pick?" />
        <p>
          Overall from the seven available Cones, there's one who is the clear
          winner and will serve you well:
        </p>
        <ul>
          <li>
            <strong>Today Is Another Peaceful Day (Erudition)</strong> - this
            Light Cone is one of the strongest in the Erudition category, so if
            you use the characters from this path, you will for sure not regret
            picking it.
          </li>
        </ul>
        <p>
          The rest of the Battle Pass Light Cones simply are outshone by 4-star
          or free 5-star Light Cones, so it's not worth investing into them.
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      </div>
    </DashboardLayout>
  );
};

export default HSRGuidesFHPage;

export const Head: React.FC = () => (
  <Seo
    title="Best Battle Pass Light Cone | Honkai: Star Rail | Prydwen Institute"
    description="A guide for picking the best Light Cone in the Battle Pass available in Honkai: Star Rail."
  />
);
